import axios from "axios";
import { base_api_url, get_request } from "./Api";

export const get_featured_campaigns = () => {
  return get_request("campaigns/get/boosted");
};

export const get_all_campaigns = () => {
  return get_request("campaigns/get/all");
};

export const getAllCampaignTypes = () => {
  return get_request("campaigns/get/all/type");
};

export const getCampaignById = (id) => {
  return get_request("campaigns/get/id/" + id);
};
export const getCampaignsByType = (type) => {
  return get_request("campaigns/get/by/type/" + type);
};
export const getUserWithToken = (token) => {
  return get_request("users/validate/token/" + token);
};

export const validateEmail = (uuid) => {
  return get_request("users/verify/token?identity=" + uuid);
};
export const create_user = (data) => {
  return axios.post(base_api_url + "users/create", data, {
    headers: {
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    },
  });
};

export const createCampaign = (data) => {
  return axios.post(base_api_url + "campaigns/create", data, {
    headers: {
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    },
  });
};

export const createGift = (data) => {
  return axios.post(base_api_url + "gifts/create", data, {
    headers: {
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    },
  });
};
export const makeDonation = (data) => {
  return axios.post(base_api_url + "donate/start", data, {
    headers: {
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    },
  });
};
