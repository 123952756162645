import React from "react";
import { Badge, Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { BiSolidDonateHeart } from "react-icons/bi";
import { FaPeopleCarry } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import {
  getAllCampaignTypes,
  getCampaignsByType,
} from "../../Api/ApiRequester";
import Swal from "sweetalert2";

export default class BrowseByCategory extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDark: false,
      allCampaigns: [],
      campaignTypes: [],
    };
  }
  componentDidMount() {
    var data = this.props.getProps();
    var allCampaigns = data["allCampaigns"];
    this.setState({ allCampaigns: allCampaigns });

    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
    this.getCategories();
  }

  getCategories = () => {
    getAllCampaignTypes()
      .then((res: any) => {
        var response = JSON.parse(JSON.stringify(res));
        this.setState({ campaignTypes: response["campaignTypes"] });
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unable to fetch campaign types",
          footer: '<a href="">Report this issue</a>',
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };
  sortCampaigns = (campaignId: any) => {
    this.props.setStateValue({ isLoading: true });
    getCampaignsByType(campaignId)
      .then((res: any) => {
        var response = JSON.parse(JSON.stringify(res));
        this.setState({ allCampaigns: response["campaigns"] });
        this.props.setStateValue({ isLoading: false });
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unable to get campaign",
          footer: '<a href="">Report this issue</a>',
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };
  public render() {
    return (
      <>
        <Container fluid className="mt-4 BrowseByCategory FeaturedCauses mb-4">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <hr />
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5>Browse by category</h5>
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="text-center mb-5"
                  >
                    {" "}
                    {this.state.campaignTypes &&
                      this.state.campaignTypes.map((value: any, index: any) => {
                        return (
                          <>
                            {" "}
                            <Badge
                              onClick={() =>
                                this.sortCampaigns(value["campaignTypeId"])
                              }
                              key={index}
                              bg="secondary"
                            >
                              {value["campaignType"]}
                            </Badge>{" "}
                            &nbsp;
                          </>
                        );
                      })}
                  </Col>

                  {this.state.allCampaigns &&
                    this.state.allCampaigns.map((value: any, index: any) => {
                      return (
                        <Col
                          key={index}
                          xs={12}
                          sm={12}
                          md={4}
                          lg={3}
                          xl={3}
                          className="mb-4"
                        >
                          <Card
                            onClick={() =>
                              (window.location.href =
                                "campaign/" + value["campaignId"])
                            }
                            data-bs-theme={this.state.isDark ? "dark" : "light"}
                            className=" pb-2"
                          >
                            <Card.Img
                              variant="top"
                              src={value["imageList"].split(",")[0]}
                              height={100}
                            ></Card.Img>
                            <Card.Body>
                              <h6>{value["campaignName"]}</h6>
                              <span>
                                <CiLocationOn /> Nairobi Kenya
                              </span>
                              <div
                                className="campaign_text"
                                dangerouslySetInnerHTML={{
                                  __html: value["campaignDescription"],
                                }}
                              />

                              <span>KES: {value["totalDonation"]}</span>
                              <ProgressBar
                                min={0}
                                max={100}
                                now={
                                  (value.totalDonation / value.campaignTarget) *
                                  100
                                }
                              />
                            </Card.Body>
                            <Card.Footer>
                              <Row>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                  {" "}
                                  <AiOutlineShareAlt />{" "}
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                  {" "}
                                  <AiOutlineHeart />{" "}
                                </Col>
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  className="text-center"
                                >
                                  <span>
                                    {" "}
                                    {value["donorCount"]} <FaPeopleCarry />
                                  </span>
                                </Col>
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  onClick={() =>
                                    (window.location.href =
                                      "campaign/" + value["campaignId"])
                                  }
                                >
                                  <span className="donate">
                                    <BiSolidDonateHeart /> &nbsp; Donate
                                  </span>
                                </Col>
                              </Row>
                            </Card.Footer>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
