import React from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { BsSearchHeart } from "react-icons/bs";
import logo from "../../../assets/images/logo.svg";
import "./subcomponents.scss";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
export default class Navigation extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showOffMenu: false,
      isDark: false,
    };
  }

  componentDidMount() {
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
    //@ts-ignore;
  }

  public render() {
    return (
      <>
        <Navbar className="shadow">
          <Container>
            <Navbar.Brand href="/">
              <img
                className="d-inline-block align-top"
                src={logo}
                width={150}
                height={30}
                alt="logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-center form-holder ">
              <Form className="form-inline m-0 p-0 d-none d-md-block ">
                <InputGroup>
                  <Form.Control
                    className=""
                    data-bs-theme={this.state.isDark ? "dark" : "light"}
                    type="text"
                    placeholder="Search non profits, causes or people..."
                    aria-label="Search non profits, causes or people..."
                    aria-describedby="Search non profits, causes or people..."
                  />

                  <InputGroup.Text className="" id="search">
                    <BsSearchHeart />
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end  last-menu ">
              <Navbar.Text
                onClick={() => this.setState({ showOffMenu: true })}
                className="d-none d-sm-none d-md-none  d-sm-none"
              >
                <HiOutlineMenuAlt3 />
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Offcanvas
          data-bs-theme={this.state.isDark ? "dark" : "light"}
          show={this.state.showOffMenu}
          onHide={() => this.setState({ showOffMenu: false })}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {" "}
              <img
                className="d-inline-block align-top"
                src={logo}
                width={150}
                height={30}
                alt="logo"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ListGroup variant="flush">
              <ListGroup.Item action href="/about">
                About
              </ListGroup.Item>
              <ListGroup.Item action href="/signin">
                Sign In
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  onClick={() => (window.location.href = "/signup")}
                  className="tufund_button"
                >
                  Create An Account
                </Button>
              </ListGroup.Item>
            </ListGroup>
            <div className="off-canvas-footer">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  &copy; 2023 Mtickets LTD &nbsp;{" "}
                  <a href="/#">Terms of Use &amp; Privacy Policy</a>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
