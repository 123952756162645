import React from "react";
import {Container, Row, Col, ListGroup, Tab, Image, Navbar} from "react-bootstrap";
import "../DashBoard.scss";
import logo from "../../../assets/images/logo.svg";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiSolidDonateHeart } from "react-icons/bi";
import { MdOutlineCampaign } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import Dashboard from "./Dashboard";
import ViewDonations from "./ViewDonations";

export default class DashboardMenu extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            isDark: false,
        }
    }

    componentDidMount() {
        ;
        const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
        if (isDarkSystem.matches) {
            this.setState({ isDark: true });
        }
        isDarkSystem.addListener((ev) => {
            if (ev.matches) {
                this.setState({ isDark: true });
            }
        });
    }
    public render() {
        return (
            <>
                <Container fluid  className="DashboardMenu">
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#dashboard"  data-bs-theme={this.state.isDark ? "dark" : "light"}>

                    <Row>

                        <Col xs={3} sm={3} md={2} lg={2} xl={2} className="Dashboard_menu d-none d-md-block  p-0 m-0  mh-100">
                                <ListGroup data-bs-theme={this.state.isDark ? "dark" : "light"}  className="w-100"  variant="flush">
                                    <ListGroup.Item className="pt-3 pb-3"  data-bs-theme={this.state.isDark ? "dark" : "light"}>
                                        <Image src={logo} alt="logo"/>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#dashboard">
                                        <AiOutlineDashboard />   <span>Dashboard</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#donations">
                                     <BiSolidDonateHeart/>  <span> Donations</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#campaigns">
                                    <MdOutlineCampaign/>   <span>   Campaigns</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#payouts">
                                     <GiMoneyStack/>  <span>  Payouts</span>
                                    </ListGroup.Item>
                                </ListGroup>

                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                            <>

                                <Navbar  data-bs-theme={this.state.isDark ? "dark" : "light"} className="shadow w-75 ">
                                    <Container>
                                        <Navbar.Toggle />
                                        <Navbar.Collapse className="justify-content-end">
                                            <Navbar.Text className="avatar">
                                                ZB
                                            </Navbar.Text>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>

                                <Tab.Content className="mt-5 pt-4">
                                    <Tab.Pane eventKey="#dashboard">
                                        <Dashboard/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#donations">

                                        <ViewDonations/>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#campaigns">Tab pane content 3</Tab.Pane>
                                    <Tab.Pane eventKey="#payouts">Tab pane content 4</Tab.Pane>

                                </Tab.Content>



                            </>

                        </Col>

                    </Row>
                </Tab.Container>
                </Container>
            </>
        );
    }
}