import React from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import { BiSolidDonateHeart } from "react-icons/bi";
import { BsShare } from "react-icons/bs";

export default class DonationDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      campaign: {
        campaignId: null,
        campaignName: null,
        campaignDescription: null,
        campaignTarget: null,
        campaignTypes: {
          campaignTypeId: null,
          campaignType: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        platformFee: null,
        hasGifts: null,
        user: {
          userId: null,
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          password: null,
          role: {
            id: null,
            roleName: null,
            roleDescription: null,
            createdAt: null,
            updatedAt: null,
            campaignOwner: null,
            admin: null,
            customer: null,
          },
          maidenToken: null,
          createdAt: null,
          updatedAt: null,
          verified: null,
        },
        accountNumber: null,
        statusMap: {
          statusMapId: null,
          status: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        startDate: null,
        endDate: null,
        boostedIndex: null,
        donorCount: null,
        totalDonation: null,
        imageList: "",
        createdAt: null,
        updatedAt: null,
      },
      gifts: [],
      has_gifts: false,
      donations: [],
    };
  }
  componentDidMount() {
    var data = this.props.getState();
    console.log(data);
    var campaign = data["campaign"];
    var gifts = data["gifts"];
    var has_gifts = data["hasGifts"];
    var donations = data["donations"];
    console.log(donations);
    this.setState({
      campaign: campaign,
      gifts: gifts,
      has_gifts: has_gifts,
      donations: donations,
    });
  }
  public render() {
    const Link = ({ id, children, title }: any) => (
      <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        <a href="/#">{children}</a>
      </OverlayTrigger>
    );
    return (
      <>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-sm-0 mt-md-5">
          <Card
            data-bs-theme={this.props.isDark ? "dark" : "light"}
            className="card_donate company_card"
          >
            <Card.Header>
              <h6>Raised: KES {this.state.campaign["totalDonation"]} </h6>
              <ProgressBar
                min={0}
                max={100}
                now={
                  (this.state.campaign["totalDonation"] /
                    this.state.campaign["campaignTarget"]) *
                  100
                }
              />
              <Row>
                <Col>
                  <span>{this.state.campaign["donorCount"]} Donations</span>
                </Col>
                <Col>
                  <span className="goal">
                    Goal KES {this.state.campaign["campaignTarget"]}
                  </span>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                {this.state.has_gifts ? (
                  <>
                    {" "}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="mb-2"
                    >
                      <h6>
                        {" "}
                        This donation has &nbsp;
                        <Link
                          title="If you have selected a gift, the gift will be availed to you by the organizer"
                          id="t-1"
                        >
                          gifts
                        </Link>{" "}
                      </h6>
                      {this.state.gifts &&
                        this.state.gifts.map((value: any, index: any) => {
                          return (
                            <Card
                              key={index}
                              data-bs-theme={
                                this.props.isDark ? "dark" : "light"
                              }
                              className="gift-card mb-2"
                            >
                              <Card.Img
                                variant="top"
                                src={value.giftImage}
                              ></Card.Img>
                              <Card.Body>
                                <h5>{value.giftName}</h5>
                                <h6>KES: {value.giftPrice}</h6>
                                <div dangerouslySetInnerHTML={{ __html: value.giftDescription }} />
                              </Card.Body>
                            </Card>
                          );
                        })}
                    </Col>
                  </>
                ) : (
                  <></>
                )}

                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-center mb-2"
                >
                  {" "}
                  <Button
                    onClick={() =>
                      (window.location.href =
                        "/donate/" + this.state.campaign["campaignId"])
                    }
                    className="w-100 w-sm-100 pt-2 pb-2 donate_btn"
                  >
                    <BiSolidDonateHeart /> &nbsp; Donate Now
                  </Button>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-center mb-2"
                >
                  {" "}
                  <Button className="w-100 share_btn pt-2 pb-2">
                    <BsShare /> &nbsp; Share
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-2">
                  <h6>{this.state.campaign["donorCount"]} Donation(s)</h6>
                  <ListGroup variant="flush">
                    <ListGroup.Item> </ListGroup.Item>
                    {this.state.donations &&
                      this.state.donations.map((value: any, index: any) => {
                        return (
                          <ListGroup.Item>
                            <Row>
                              <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                {" "}
                                <div
                                  data-letters={`
                                   ${
                                     value["firstName"] === null
                                       ? ""
                                       : value["firstName"].charAt(0)
                                   } 
                                         ${
                                           value["lastName"] === null
                                             ? ""
                                             : value["lastName"].charAt(0)
                                         }
                                  `}
                                >
                                  {" "}
                                  {value["firstName"] === null ? (
                                    <>Anonymous </>
                                  ) : (
                                    <> {value["firstName"]} </>
                                  )}{" "}
                                  &nbsp;
                                  {value["lastName"]}
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                                className="pt-1 text-end"
                              >
                                {value["totalAmount"]}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  }
}
