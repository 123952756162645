import React, { Component } from "react";
//@ts-ignore;
import { Helmet } from "react-helmet";
//@ts-ignore;
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import "./LandingPage.scss";
import Navigation from "./Subcomponents/Navigation";
import Jumbotron from "./Subcomponents/Jumbotron";
import FeaturedCauses from "./Subcomponents/FeaturedCauses";
import BrowseByCategory from "./Subcomponents/BrowseByCategory";
import Footer from "../Reusables/footer";
import { get_all_campaigns, get_featured_campaigns } from "../Api/ApiRequester";
import Swal from "sweetalert2";
// import MticketsBanner from "./Subcomponents/MticketsBanner";

class LandingPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      featuredCampaigns: [],
      allCampaigns: [],
    };
  }

  async componentDidMount() {
    await this.handleGetCampaigns();
  }

  handleShowPreloader = () => {
    this.setState({ isLoading: false });
  };

  handleGetCampaigns = async () => {
    get_featured_campaigns().then((res: any) => {
      var json = JSON.parse(JSON.stringify(res));
      var featuredCampaigns = json["campaigns"];
      this.setState({ featuredCampaigns: featuredCampaigns });
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unable to fetch campaigns",
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    })
    await this.handleGetAllCampaigns();
  };
  handleGetAllCampaigns = async () => {
    get_all_campaigns().then((res: any) => {
      var response = JSON.parse(JSON.stringify(res));
      var allCampaigns = response["campaigns"];
      this.setState({ allCampaigns: allCampaigns });
      this.setState({ isLoading: false });
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unable to fetch campaigns",
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    });
  };

  getState = () => {
    return this.state;
  };

  setStateValue = (data: any) => {
    this.setState(data);
  };

  public render() {
    return (
      <>
       <Helmet>
          <title> Tufund - Home </title>
          <meta
            name="description"
            content="Discover the power of giving with Tufund, your trusted fundraising platform. Join our community of passionate individuals and organizations dedicated to making a difference. Start or support impactful campaigns, raise funds for causes you care about, and be part of positive change. Together, we can turn dreams into reality. Join Tufund today and make a meaningful impact."
          />
          <link rel="canonical" href="http://tufund.org" />
       
        </Helmet>
      <LoadingOverlay
        active={this.state.isLoading}
        spinner={<HashLoader color="#fff" />}
      >
        <Navigation />
        <Jumbotron />
        {this.state.featuredCampaigns.length === 0 ? (
          <> </>
        ) : (
          <FeaturedCauses getProps={this.getState} />
        )}
        {this.state.allCampaigns.length === 0 ? (
          <> </>
        ) : (
          <BrowseByCategory
            getProps={this.getState}
            setStateValue={this.setStateValue}
          />
        )}

        {/*<MticketsBanner/>*/}
        <Footer />
      </LoadingOverlay>
      </>
    );
  }
}

export default LandingPage;
