import React from "react";
import {Container, Row, Col, Card} from "react-bootstrap";
import "../DashBoard.scss";
import { BsCaretUp, BsCaretDown } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import { PiSigmaThin } from "react-icons/pi";
import { Chart, Tooltip, Axis, Area, Line } from 'viser-react';
interface  DashboardInterface {
    data: any;
    scale:any;
    isDark:boolean
}
export default class Dashboard extends React.Component<{}, DashboardInterface>{
    constructor(props: {}) {
        super(props);
        this.state = {
            isDark: false,
            data : [
                { year: '1991', value: 15468 },
                { year: '1992', value: 16100 },
                { year: '1993', value: 15900 },
                { year: '1994', value: 17409 },
                { year: '1995', value: 17000 },
                { year: '1996', value: 31056 },
                { year: '1997', value: 31982 },
                { year: '1998', value: 32040 },
                { year: '1999', value: 33233 },
            ],
            scale : [{
                dataKey: 'value',
                min: 10000,
            }, {
                dataKey: 'year',
                min: 0,
                max: 1,
            }]
        }
    }

    componentDidMount() {

        const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
        if (isDarkSystem.matches) {
            this.setState({ isDark: true });
        }
        isDarkSystem.addListener((ev) => {
            if (ev.matches) {
                this.setState({ isDark: true });
            }
        });
    }
    public render() {
        const crosshairs: any = {
            type: 'y',
            style: {},
        };

        return (
            <>
            <Container fluid className="DashboardHome">
                <Row>
                    <Col xs={6} sm={6} md={2} lg={2} xl={2} className="mb-3">
                        <Card   data-bs-theme={this.state.isDark ? "dark" : "light"}>
                            <Card.Header>
                                Total Donations
                            </Card.Header>
                            <Card.Body>
                              <GiMoneyStack/>  KES 20,000
                            </Card.Body>
                            <Card.Footer>
                               <BsCaretUp className="text-success"/> 12% increase
                            </Card.Footer>
                        </Card>

                    </Col>

                    <Col xs={6} sm={6} md={2} lg={2} xl={2} className="mb-3">
                        <Card   data-bs-theme={this.state.isDark ? "dark" : "light"}>
                            <Card.Header>
                                Total Donors
                            </Card.Header>
                            <Card.Body>
                                <PiSigmaThin />   10
                            </Card.Body>
                            <Card.Footer>
                                <BsCaretUp className="text-success"/>     12% increase
                            </Card.Footer>
                        </Card>

                    </Col>

                    <Col xs={6} sm={6} md={2} lg={2} xl={2} className="mb-3">
                        <Card   data-bs-theme={this.state.isDark ? "dark" : "light"}>
                            <Card.Header>
                                Total Campaigns
                            </Card.Header>
                            <Card.Body>
                                <PiSigmaThin /> 1
                            </Card.Body>
                            <Card.Footer>
                                <BsCaretDown className="text-danger"/>     12% decrease
                            </Card.Footer>

                        </Card>

                    </Col>


                    <Col xs={6} sm={6} md={2} lg={2} xl={2} className="mb-3">
                        <Card   data-bs-theme={this.state.isDark ? "dark" : "light"}>
                            <Card.Header>
                                  Net  Payable
                            </Card.Header>
                            <Card.Body>
                                <GiMoneyStack/>         KES 20,000
                            </Card.Body>
                            <Card.Footer>
                                <BsCaretUp className="text-success"/>  12% increase
                            </Card.Footer>
                        </Card>

                    </Col>

                    <Col xs={6} sm={6} md={2} lg={2} xl={2} className="mb-3">
                        <Card   data-bs-theme={this.state.isDark ? "dark" : "light"}>
                            <Card.Header>
                                Total Gifts
                            </Card.Header>
                            <Card.Body>
                                <PiSigmaThin />   10
                            </Card.Body>
                            <Card.Footer>
                                <BsCaretUp className="text-success"/>   12% increase
                            </Card.Footer>
                        </Card>

                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-3 ">
                        <h6 className="mb-2"> Donations over time</h6>
                        {/* @ts-ignore */}
                        <Chart forceFit height={400} data={this.state.data} scale={this.state.scale}>
                            <Tooltip crosshairs={crosshairs} />
                            <Axis dataKey="value" />
                            <Line position="year*value" size={2} />
                            <Area style={{ fill: '#79C00A' }} position="year*value" />
                        </Chart>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}