import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import "../DashBoard.scss";
import DataTable from 'react-data-table-component';

interface DataRow {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    campaign_id: number;
    total_amount: number;
    is_paid: boolean;
    gift_quantity: number;
}

export default class ViewDonations extends React.Component<any, any>{
    constructor(props:any) {
        super(props);
        this.state ={
            options:[
                {
                    value:"My Campaign 1", label:"My Campaign"
                }
            ],
            selectedOption: null,
            isDark: false,
            filterText: '',
            resetPaginationToggle: false,
            columns : [
                {
                    name: 'id',
                    selector: (row : DataRow)  => row.id,

                },
                {
                    name: 'email',
                    selector:  (row : DataRow)  => row.email,
                },
                {
                    name: 'first_name',
                    selector:  (row : DataRow)  => row.first_name,
                },
                {
                    name: 'last_name',
                    selector:  (row : DataRow)  => row.last_name,
                },
                {
                    name: 'campaign_id',
                    selector:  (row : DataRow)  => row.campaign_id,
                },
                {
                    name: 'total_amount',
                    selector:  (row : DataRow)  => row.total_amount,
                },
                {
                    name: 'is_paid',
                    selector:  (row : DataRow)  => row.is_paid,
                },
                {
                    name: 'gift_quantity',
                    selector:  (row : DataRow)  => row.gift_quantity,
                },
            ],
            data:[
                {
                    id: 1,
                    email: 'john.doe@example.com',
                    first_name: 'John',
                    last_name: 'Doe',
                    campaign_id: 101,
                    total_amount: 150.75,
                    is_paid: true,
                    gift_quantity: 3,
                },
                {
                    id: 2,
                    email: 'jane.smith@example.com',
                    first_name: 'Jane',
                    last_name: 'Smith',
                    campaign_id: 102,
                    total_amount: 200.50,
                    is_paid: false,
                    gift_quantity: 2,
                },
                // Add more sample data as needed
            ]

        }
    }
    componentDidMount() {

        const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
        if (isDarkSystem.matches) {
            this.setState({ isDark: true });
        }
        isDarkSystem.addListener((ev) => {
            if (ev.matches) {
                this.setState({ isDark: true });
            }
        });
    }
    handleCampaignSelection = (campaign:any) =>{
        this.setState({selectedOption:campaign})
    }
    handleClear = () => {
        const { filterText, resetPaginationToggle } = this.state;
        if (filterText) {
            this.setState({
                resetPaginationToggle: !resetPaginationToggle,
                filterText: '',
            });
        }
    };
    FilterComponent = () => {
        const { filterText } = this.state;

        return (
            <div>
                <input
                    type="text"
                    placeholder="Filter by name"
                    value={filterText}
                    onChange={e => this.setState({ filterText: e.target.value })}
                />
                <button onClick={this.handleClear}>Clear</button>
            </div>
        );
    };
    public render() {
        const animatedComponents = makeAnimated();
        const { filterText, resetPaginationToggle } = this.state;

        const filteredItems = this.state.data.filter(
            (item:any) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
        );
        // @ts-ignore
        return (
            <>
           <Container className="ViewDonations">
               <Row>
                   <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                       <h6 className="mb-2 mt-2 "> Select Campaign </h6>
                       <Select
                           data-bs-theme={this.state.isDark ? "dark" : "light"}
                           value={this.state.selectedOption}
                           onChange={this.handleCampaignSelection}
                           options={this.state.options}
                           components={animatedComponents}
                       />
                   </Col>

                   <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-5">
                       <DataTable
                           subHeaderComponent={this.FilterComponent()}

                           columns={this.state.columns}
                           data={this.state.data}
                           selectableRows
                           pagination
                           theme={this.state.isDark ? "solarized" : ""}
                       />

                   </Col>
               </Row>
           </Container>
            </>
        );
    }
}