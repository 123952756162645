//var BASE_API_URL = "http://209.38.176.36/api/v2/";
// var BASE_API_URL = "http://localhost:7077/api/v2/";
var BASE_API_URL = "https://tufund.org/api/v2/";

export const post_request = (data, url_suffix) => {
  var url = BASE_API_URL + url_suffix;
  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    }),
    body: data,
  }).then((res) => res.json());
};
export const multipart_request = (data, url_suffix) => {
  var url = BASE_API_URL + url_suffix;
  return fetch(url, {
    mode: "no-cors",
    method: "POST",
    headers: new Headers({
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    }),
    body: data,
  }).then((res) => res.json());
};
export const get_request = (url_suffix) => {
  var url = BASE_API_URL + url_suffix;
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Basic dHVmdW5kQWRtaW46YWRtaW5QYXNza2V5R3JhbnQ",
    }),
  }).then((res) => res.json());
};

export const get_request_custom = (url) => {
  return fetch(url, {
    method: "GET",
  }).then((res) => res.json());
};

export const base_api_url = BASE_API_URL;
