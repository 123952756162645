import React, { Component } from "react";
// @ts-ignore
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import LandingPage from "../LandingPage";
import Authentication from "../Authentication";
import CreateCampaign from "../DashBoard/SubComponents/CreateCampaign";
import SingleCampaign from "../SingleCampaign";
import Donate from "../Donate";
import VerifyMail from "../Authentication/Subcomponents/verify_email";
import CreateGift from "../DashBoard/SubComponents/CreateGift";
import DashBoard from "../DashBoard";
class App extends Component<any, any> {
  public render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/signup" component={Authentication} />
            <Route
              exact
              path="/dashboard/create/campaign/:uuid"
              component={CreateCampaign}
            />
            <Route exact path="/campaign/:id" component={SingleCampaign} />
            <Route exact path="/donate/:id" component={Donate} />
            <Route exact path="/account/verify/:uuid" component={VerifyMail} />
            <Route exact path="/dashboard/create/gift/:campaignId" component={CreateGift} />
            <Route exact path="/dashboard" component={DashBoard} />


          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
