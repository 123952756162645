import React, { Component } from "react";
//@ts-ignore;
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import { BsCheckCircleFill } from "react-icons/bs";
import ConfettiExplosion from "react-confetti-explosion";
import Navigation from "../LandingPage/Subcomponents/Navigation";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import "./Donate.scss";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { getCampaignById, makeDonation } from "../Api/ApiRequester";
import Swal from "sweetalert2";

export default class Donate extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      has_gift: true,
      isDark: false,
      bottomSheetOpen: false,
      noReward: true,
      giftId: null,
      totalAmount: 0,
      tip: 0,
      email: "",
      phoneNumber: "",
      campaign: {
        campaignId: null,
        campaignName: null,
        campaignDescription: null,
        campaignTarget: null,
        campaignTypes: {
          campaignTypeId: null,
          campaignType: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        platformFee: null,
        hasGifts: null,
        user: {
          userId: null,
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          password: null,
          role: {
            id: null,
            roleName: null,
            roleDescription: null,
            createdAt: null,
            updatedAt: null,
            campaignOwner: null,
            admin: null,
            customer: null,
          },
          maidenToken: null,
          createdAt: null,
          updatedAt: null,
          verified: null,
        },
        accountNumber: null,
        statusMap: {
          statusMapId: null,
          status: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        startDate: null,
        endDate: null,
        boostedIndex: null,
        donorCount: null,
        totalDonation: null,
        imageList: "",
        createdAt: null,
        updatedAt: null,
      },
      gifts: [],
      donations: [],
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.getCampaign(id);
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
  }

  getCampaign = (id: any) => {
    getCampaignById(id)
      .then((res: any) => {
        var response = JSON.parse(JSON.stringify(res));
        var campaigns = response["campaign"];
        var donations = response["donations"];
        var has_gifts = campaigns["hasGifts"];
        this.setState({
          campaign: campaigns,
          hasGifts: has_gifts,
          donations: donations,
        });
        if (has_gifts) {
          console.log(response["gifts"]);
          this.setState({ gifts: response["gifts"] });
        }
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unable to get campaign",
          footer: '<a href="">Report this issue</a>',
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };
  makeDonation = (e: any) => {
    e.preventDefault();
    //validations
    var email = this.state.email;
    var mobile_number = this.state.phoneNumber;
    var total_donations = this.state.totalAmount;

    if (email === "") {
      toast.error("Please enter your email");
    } else {
      if (mobile_number === "") {
        toast.error("Please enter your mobile number");
      } else {
        if (total_donations < 10) {
          toast.error("Your donation amount is too low");
        } else {
          const mobileNumber = parsePhoneNumber(mobile_number, "KE");
          var phoneNumber =
            mobileNumber["countryCallingCode"] + mobileNumber["nationalNumber"];
          this.setState({ phoneNumber: phoneNumber });
          var json = {
            campaign_id: parseInt(this.state.campaign["campaignId"]),
            mobileNumber: phoneNumber,
            amount: parseInt(total_donations),
            tip: 0,
            gift_id: parseInt(this.state.giftId),
            has_gift: !this.state.noReward,
            gift_quantity: 1,
            email: email,
          };
          const response = this.handleApiCall(json);
          toast.promise(response, {
            loading: "Starting donation",
            success: "Donation was submitted!",
            error: "Error! Please Retry",
          });
          response
            .then((res: any) => {
              console.log(res);
              this.setState({ modalShow: true });
            })
            .catch((error: any) => {
              // var errors = error["response"]["data"]["error"];
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Unable to make donation",
                footer: '<a href="">Report this issue</a>',
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            });
        }
      }
    }
  };
  handleApiCall = async (data: any) => {
    return makeDonation(data);
  };
  public render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            {this.state.campaign["campaignName"] === null
              ? "Tufund"
              : `Donate To ${this.state.campaign["campaignName"]}`}
          </title>
          <meta
            name="description"
            content={
              this.state.campaign["campaignDescription"] === "null"
                ? "Discover the power of giving with Tufund, your trusted fundraising platform. Join our community of passionate individuals and organizations dedicated to making a difference. Start or support impactful campaigns, raise funds for causes you care about, and be part of positive change. Together, we can turn dreams into reality. Join Tufund today and make a meaningful impact."
                : `Donate for ${this.state.campaign["campaignDescription"]}`
            }
          />

          <link
            rel="canonical"
            href={
              "http://tufund.org/campaign/" + this.state.campaign["campaignId"]
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@tufund" />
          <meta
            name="twitter:title"
            content={this.state.campaign["campaignName"]}
          />
          <meta
            name="twitter:description"
            content={this.state.campaign["campaignDescription"]}
          />
          <meta
            name="twitter:image"
            content={this.state.campaign["imageList"].split(",")[0]}
          />

          <meta
            property="og:title"
            content={this.state.campaign["campaignName"]}
          />
          <meta
            property="og:description"
            content={this.state.campaign["campaignDescription"]}
          />
          <meta
            property="og:image"
            content={this.state.campaign["imageList"].split(",")[0]}
          />
          <meta
            property="og:url"
            content={
              "http://tufund.org/campaign/" + this.state.campaign["campaignId"]
            }
          />
        </Helmet>
        <Toaster position="top-center" />
        <Navigation />
        <Container className="Donate mt-5">
          <Row>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} className="mt-5">
              <div className="text-end">
                <h6>
                  {" "}
                  <CloseButton
                    onClick={() =>
                      (window.location.href =
                        "/campaign/" + this.state.campaign["campaignId"])
                    }
                    variant={this.state.isDark ? "white" : "black"}
                    aria-label="Close"
                  />
                </h6>
              </div>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h6>
                  {" "}
                  You are supporting{" "}
                  <b>
                    {" "}
                    {this.state.campaign["user"]["firstName"]}{" "}
                    {this.state.campaign["user"]["lastName"]}:{" "}
                    {this.state.campaign["campaignName"]}
                  </b>
                </h6>

                <Card
                  onClick={() =>
                    this.setState({ giftId: null, noReward: true })
                  }
                  data-bs-theme={this.state.isDark ? "dark" : "light"}
                >
                  <Card.Header>
                    {this.state.noReward ? (
                      <>
                        {" "}
                        <IoIosRadioButtonOn />
                      </>
                    ) : (
                      <>
                        {" "}
                        <IoIosRadioButtonOff />{" "}
                      </>
                    )}{" "}
                    &nbsp;
                    {this.state.has_gift ? (
                      <> Donate without gift</>
                    ) : (
                      <> Enter an Amount </>
                    )}
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>KES</InputGroup.Text>
                        <Form.Control
                          value={this.state.totalAmount}
                          onChange={(e: any) =>
                            this.setState({ totalAmount: e.target.value })
                          }
                          disabled={!this.state.noReward}
                          required
                          min={2}
                          type="text"
                          maxLength={5}
                          pattern="\d*"
                          aria-label="Amount "
                        />
                        <InputGroup.Text>.00</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Card.Body>
                </Card>
                <div
                  className={this.state.has_gift ? "hr-sect mb-3" : "d-none"}
                >
                  OR
                </div>
              </Col>
              <Row className={this.state.has_gift ? "" : "d-none"}>
                {this.state.gifts &&
                  this.state.gifts.map((value: any, index: any) => {
                    return (
                      <Col
                        xs={12}
                        sm={12}
                        m6={6}
                        lg={6}
                        xl={6}
                        className="mb-2"
                      >
                        <Card
                          onClick={() =>
                            this.setState({
                              giftId: value["giftId"],
                              noReward: false,
                              totalAmount: value.giftPrice,
                            })
                          }
                          key={index}
                          data-bs-theme={this.state.isDark ? "dark" : "light"}
                          className="gift-card mb-2"
                        >
                          <Card.Header>
                            {this.state.giftId === value["giftId"] ? (
                              <>
                                {" "}
                                <IoIosRadioButtonOn />
                              </>
                            ) : (
                              <>
                                {" "}
                                <IoIosRadioButtonOff />{" "}
                              </>
                            )}{" "}
                            &nbsp; KES {value.giftPrice}
                          </Card.Header>
                          <Card.Img
                            variant="top"
                            src={value.giftImage}
                          ></Card.Img>
                          <Card.Body>
                            <h5>{value.giftName}</h5>
                            <h6>KES: {value.giftPrice}</h6>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: value.giftDescription,
                              }}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Table
                    className="mb-3"
                    borderless
                    variant={this.state.isDark ? "dark" : "light"}
                  >
                    <tbody>
                      <tr>
                        <td>Your Donation</td>
                        <td></td>
                      </tr>
                      <tr className="total_data">
                        <td>Your Donation</td>
                        <td className="text-end">KES 200</td>
                      </tr>
                      <tr className="total_data">
                        <td>Tufund Tip</td>
                        <td className="text-end">KES 0</td>
                      </tr>
                      <tr>
                        <td>Total </td>
                        <td className="text-end">KES 0</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Form.Group className="mb-2">
                    <Form.Check
                      type="checkbox"
                      label="I want to share my details with event organizer"
                      id="1"
                    />
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email address"
                      className="mb-3"
                      data-bs-theme={this.state.isDark ? "dark" : "light"}
                    >
                      <Form.Control
                        value={this.state.email}
                        onChange={(e: any) =>
                          this.setState({ email: e.target.value })
                        }
                        type="email"
                        placeholder="name@example.com"
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Phone Number"
                      className="mb-3"
                      data-bs-theme={this.state.isDark ? "dark" : "light"}
                    >
                      <Form.Control
                        value={this.state.phoneNumber}
                        onChange={(e: any) =>
                          this.setState({ phoneNumber: e.target.value })
                        }
                        type="text"
                        pattern="\d*"
                        placeholder="07********"
                        className="number_text"
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-2 text-center">
                    <Button
                      type="button"
                      onClick={(e) => this.makeDonation(e)}
                      className="tufund_button w-75 pt-2 pb-2"
                    >
                      Donate
                    </Button>
                  </Form.Group>
                  <div className="hr-sect mb-3">info</div>
                  <p>
                    You are protected <br />
                    We do thorough background checks on fundraisers and their
                    beneficiaries, to ensure that you are donation for a valid
                    cause.
                  </p>
                  <p>
                    In case of question, concerns, please reach out to us
                    through info@tufund.ord
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          animation={true}
          centered
          size="sm"
          data-bs-theme={this.state.isDark ? "dark" : "light"}
          className="notificationModal"
        >
          <Modal.Body
            className="text-center"
            data-bs-theme={this.state.isDark ? "dark" : "light"}
          >
            <h6>
              {" "}
              <BsCheckCircleFill /> Your donation is being processed.
            </h6>
            <ConfettiExplosion
              particleCount={200}
              particleSize={6}
              duration={5000}
              onComplete={() => this.setState({ modalShow: false })}
              zIndex={10}
              force={0.8}
              height="30vh"
              width={600}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
