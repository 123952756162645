import React, { Component } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
//@ts-ignore;
import { Helmet } from "react-helmet";
import "./SingleCampaign.scss";
import Navigation from "../LandingPage/Subcomponents/Navigation";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import CampaignDetails from "./Subcomponents/CampaignDetails";
import DonationDetails from "./Subcomponents/DonationDetails";
import { CiLocationOn } from "react-icons/ci";
import { BiSolidDonateHeart } from "react-icons/bi";
import { BsShare } from "react-icons/bs";
import { getCampaignById } from "../Api/ApiRequester";
import Swal from "sweetalert2";
export default class SingleCampaign extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDark: false,
      bottomSheetOpen: false,
      campaign: {
        campaignId: null,
        campaignName: null,
        campaignDescription: null,
        campaignTarget: null,
        campaignTypes: {
          campaignTypeId: null,
          campaignType: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        platformFee: null,
        hasGifts: null,
        user: {
          userId: null,
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          password: null,
          role: {
            id: null,
            roleName: null,
            roleDescription: null,
            createdAt: null,
            updatedAt: null,
            campaignOwner: null,
            admin: null,
            customer: null,
          },
          maidenToken: null,
          createdAt: null,
          updatedAt: null,
          verified: null,
        },
        accountNumber: null,
        statusMap: {
          statusMapId: null,
          status: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        startDate: null,
        endDate: null,
        boostedIndex: null,
        donorCount: null,
        totalDonation: null,
        imageList: "",
        createdAt: null,
        updatedAt: null,
      },
      gifts: [],
      hasGifts: false,
      donations: [],
    };
  }
  componentDidMount() {
    var id = this.props.match.params.id;
    this.getCampaign(id);
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
  }

  getCampaign = (id: any) => {
    getCampaignById(id)
      .then((res: any) => {
        var response = JSON.parse(JSON.stringify(res));
        var campaigns = response["campaign"];
        var donations = response["donations"];
        var has_gifts = campaigns["hasGifts"];
        this.setState({
          campaign: campaigns,
          hasGifts: has_gifts,
          donations: donations,
        });
        if (has_gifts) {
          this.setState({ gifts: response["gifts"] });
        }
      })
      .catch((_temmm: any) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unable to get campaigns",
          footer: '<a href="">Report this issue</a>',
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };

  getState = () => {
    return this.state;
  };
  public render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            {this.state.campaign["campaignName"] === null
              ? "Tufund"
              : this.state.campaign["campaignName"]}
          </title>
          <meta
            name="description"
            content={
              this.state.campaign["campaignDescription"] === "null"
                ? "Discover the power of giving with Tufund, your trusted fundraising platform. Join our community of passionate individuals and organizations dedicated to making a difference. Start or support impactful campaigns, raise funds for causes you care about, and be part of positive change. Together, we can turn dreams into reality. Join Tufund today and make a meaningful impact."
                : this.state.campaign["campaignName"]
            }
          />
          

          <link
            rel="canonical"
            href={
              "http://tufund.org/campaign/" + this.state.campaign["campaignId"]
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@tufund" />
          <meta
            name="twitter:title"
            content={this.state.campaign["campaignName"]}
          />
          <meta
            name="twitter:description"
            content={this.state.campaign["campaignDescription"]}
          />
          <meta
            name="twitter:image"
            content={this.state.campaign["imageList"].split(",")[0]}
          />

          <meta
            property="og:title"
            content={this.state.campaign["campaignName"]}
          />
          <meta
            property="og:description"
            content={this.state.campaign["campaignDescription"]}
          />
          <meta
            property="og:image"
            content={this.state.campaign["imageList"].split(",")[0]}
          />
          <meta
            property="og:url"
            content={
              "http://tufund.org/campaign/" + this.state.campaign["campaignId"]
            }
          />
        </Helmet>
        <Navigation />
        <Container className="SingleCampaign" fluid>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              className="SingleCampaign_header"
              style={{
                backgroundImage: `url(${
                  this.state.campaign["imageList"].split(",")[0]
                })`,
              }}
            ></Col>
          </Row>
          <Container className="SingleCampaign_body mb-5">
            <Row>
              {this.state.campaign["campaignName"] == null ? (
                <> </>
              ) : (
                <>
                  <CampaignDetails
                    getState={this.getState}
                    isDark={this.state.isDark}
                  />{" "}
                </>
              )}
              {this.state.campaign["campaignName"] == null ? (
                <> </>
              ) : (
                <>
                  <DonationDetails
                    getState={this.getState}
                    isDark={this.state.isDark}
                  />
                </>
              )}
            </Row>
          </Container>

          <Row className="d-sm-block d-md-none  footer-dialogue pt-3 pb-5">
            <Container>
              <Row>
                <Col xs={6} sm={6} lg={6} md={6} xl={6}>
                  <h6>{this.state.campaign["campaignName"]}</h6>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  lg={6}
                  md={6}
                  xl={6}
                  onClick={() => this.setState({ bottomSheetOpen: true })}
                >
                  <Button className=" donate_btn w-100 pt-2 pb-2">
                    {" "}
                    See Campaign Story
                  </Button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        <BottomSheet
          data-bs-theme={this.state.isDark ? "dark" : "light"}
          className="SingleCampaign SingleCampaign_body bottom_sheet"
          onDismiss={() => this.setState({ bottomSheetOpen: false })}
          defaultSnap={({ maxHeight }) => maxHeight / 1.1}
          snapPoints={({ maxHeight }) => maxHeight / 1.1}
          open={this.state.bottomSheetOpen}
        >
          <>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    data-bs-theme={this.state.isDark ? "dark" : "light"}
                    className="company_card campaign_card mb-1 "
                  >
                    <Card.Header>
                      <h5>
                        {this.state.campaign["user"]["firstName"]}{" "}
                        {this.state.campaign["user"]["lastName"]} is organizing
                        this fundraiser
                      </h5>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="campaign_text" />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.campaign["campaignDescription"],
                            }}
                          ></p>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Row className="pt-2 pb-2">
                        <Col className="text-center">
                          {" "}
                          <Button
                            onClick={() =>
                              (window.location.href =
                                "/donate/" + this.state.campaign["campaignId"])
                            }
                            className="w-100 w-sm-100 pt-2 pb-2 donate_btn"
                          >
                            <BiSolidDonateHeart /> &nbsp; Donate Now
                          </Button>
                        </Col>
                        <Col className="text-center">
                          {" "}
                          <Button className="w-100 share_btn pt-2 pb-2">
                            <BsShare /> &nbsp; Share
                          </Button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                  <Card
                    data-bs-theme={this.state.isDark ? "dark" : "light"}
                    className="company_card organizer_card  mt-3"
                  >
                    <Card.Body>
                      <Row>
                        <h5>
                          {" "}
                          <b>Organizer</b>
                        </h5>
                        <Col xs={8} sm={8} md={8} lg={8}>
                          <h5 className="p-0 m-0">
                            {this.state.campaign["user"]["firstName"]}{" "}
                            {this.state.campaign["user"]["lastName"]}{" "}
                          </h5>
                          <span>
                            <CiLocationOn /> Nairobi Kenya
                          </span>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} className="text-end">
                          <Button className=" donate_btn pt-2 pb-2">
                            {" "}
                            Contact
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        </BottomSheet>
      </>
    );
  }
}
