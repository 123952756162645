import React from "react";
import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
//@ts-ignore;
import Slider from "react-slick";
import "./subcomponents.scss";
import { GrNext, GrPrevious } from "react-icons/gr";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BiSolidDonateHeart } from "react-icons/bi";
import { FaPeopleCarry } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
export default class FeaturedCauses extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDark: false,
      featuredCampaigns: [],
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        lazyLoad: "ondemand",
        cssEase: "linear",
        arrows: true,
        nextArrow: <GrNext className="white_svg" />,
        prevArrow: <GrPrevious className="white_svg" />,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.fetchData();
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
  }

  fetchData = () => {
    var data = this.props.getProps();
    const campaigns = data["featuredCampaigns"];
    this.setState({ featuredCampaigns: campaigns });
  };

  public render() {
    return (
      <>
        <Container fluid className="mt-5 FeaturedCauses mb-5 ">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <hr />
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h4>Featured Causes</h4>
                  </Col>
                  <Slider {...this.state.settings}>
                    {this.state.featuredCampaigns &&
                      this.state.featuredCampaigns.map(
                        (value: any, index: any) => {
                          return (
                            <div className="-s" key={index}>
                              <Card
                                onClick={() =>
                                  (window.location.href =
                                    "campaign/" + value["campaignId"])
                                }
                                data-bs-theme={
                                  this.state.isDark ? "dark" : "light"
                                }
                                className=" pb-2"
                              >
                                <Card.Img
                                  variant="top"
                                  src={value["imageList"].split(",")[0]}
                                  height={100}
                                ></Card.Img>
                                <Card.Body>
                                  <h6>{value["campaignName"]}</h6>
                                  <span>
                                    <CiLocationOn /> Nairobi Kenya
                                  </span>
                                  <div
                                    className="campaign_text"
                                    dangerouslySetInnerHTML={{
                                      __html: value["campaignDescription"],
                                    }}
                                  />

                                  <span>KES: {value["totalDonation"]}</span>
                                  <ProgressBar
                                    min={0}
                                    max={100}
                                    now={
                                      (value.totalDonation /
                                        value.campaignTarget) *
                                      100
                                    }
                                  />
                                </Card.Body>
                                <Card.Footer>
                                  <Row>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                      {" "}
                                      <AiOutlineShareAlt />{" "}
                                    </Col>
                                    {/*<Col xs={2} sm={2} md={2} lg={2} xl={2}> <AiOutlineHeart/> </Col>*/}
                                    <Col
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="text-end"
                                    >
                                      <span>
                                        {" "}
                                        {value["donorCount"]} <FaPeopleCarry />
                                      </span>
                                    </Col>
                                    <Col
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="text-end"
                                      onClick={() =>
                                        (window.location.href =
                                          "campaign/" + value["campaignId"])
                                      }
                                    >
                                      <span className="donate">
                                        <BiSolidDonateHeart /> &nbsp; Donate
                                      </span>
                                    </Col>
                                  </Row>
                                </Card.Footer>
                              </Card>
                            </div>
                          );
                        }
                      )}
                  </Slider>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
