import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./subcomponents.scss";

export default class Jumbotron extends React.Component<any, any> {
  public render() {
    return (
      <>
        <Container fluid className="Jumbotron pb-5">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="pt-5">
              <Container>
                <Row className="mt-5">
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="Jumbotron_info"
                  >
                    <h5>
                      Your trusted <br /> network for doing <br /> good
                    </h5>
                    <h6>For nonprofits, causes or fundraising</h6>
                    <p>
                      Explore, donate, and share. Over one million registered
                      charities.
                    </p>
                    <Button
                      onClick={() => (window.location.href = "/signup")}
                      className="tufund_button pt-2 pb-2 mb-2 "
                    >
                      Get Started For Free
                    </Button>
                    <br />
                    <span>or sign up with email</span>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    {" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
