import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BiCategory, BiSolidDonateHeart } from "react-icons/bi";
import { BsShare } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";

export default class CampaignDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      campaign: {
        campaignId: null,
        campaignName: null,
        campaignDescription: null,
        campaignTarget: null,
        campaignTypes: {
          campaignTypeId: null,
          campaignType: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        platformFee: null,
        hasGifts: null,
        user: {
          userId: null,
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          password: null,
          role: {
            id: null,
            roleName: null,
            roleDescription: null,
            createdAt: null,
            updatedAt: null,
            campaignOwner: null,
            admin: null,
            customer: null,
          },
          maidenToken: null,
          createdAt: null,
          updatedAt: null,
          verified: null,
        },
        accountNumber: null,
        statusMap: {
          statusMapId: null,
          status: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          active: null,
        },
        startDate: null,
        endDate: null,
        boostedIndex: null,
        donorCount: null,
        totalDonation: null,
        imageList: "",
        createdAt: null,
        updatedAt: null,
      },
    };
  }
  componentDidMount() {
    var data = this.props.getState();
    console.log(data);
    var campaign = data["campaign"];
    this.setState({ campaign: campaign });
  }
  public render() {
    return (
      <>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className="mt-5 pb-4 ">
          <Card
            data-bs-theme={this.props.isDark ? "dark" : "light"}
            className="company_card mb-4"
          >
            <Card.Body>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <h5>{this.state.campaign["campaignName"]}</h5>
                  <span>
                    <CiLocationOn /> Nairobi Kenya
                  </span>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <h6 className="pt-0">
                    {" "}
                    <BiCategory />{" "}
                    <b>
                      {this.state.campaign["campaignTypes"]["campaignType"]}
                    </b>{" "}
                  </h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/*show this on bottom sheet?*/}
          <Card
            data-bs-theme={this.props.isDark ? "dark" : "light"}
            className="company_card campaign_card mb-1  d-none d-md-block"
          >
            <Card.Header>
              <h5>
                {this.state.campaign["user"]["firstName"]}{" "}
                {this.state.campaign["user"]["lastName"]} is organizing this
                fundraiser
              </h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.campaign["campaignDescription"],
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row className="pt-2 pb-2">
                <Col className="text-center">
                  {" "}
                  <Button
                    onClick={() =>
                      (window.location.href =
                        "/donate/" + this.state.campaign["campaignId"])
                    }
                    className="w-100 w-sm-100 pt-2 pb-2 donate_btn"
                  >
                    <BiSolidDonateHeart /> &nbsp; Donate Now
                  </Button>
                </Col>
                <Col className="text-center">
                  {" "}
                  <Button className="w-100 share_btn pt-2 pb-2">
                    <BsShare /> &nbsp; Share
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
          <Card
            data-bs-theme={this.props.isDark ? "dark" : "light"}
            className="company_card organizer_card d-none d-md-block mt-3"
          >
            <Card.Body>
              <Row>
                <h5>
                  {" "}
                  <b>Organizer</b>
                </h5>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <h5 className="p-0 m-0">
                    {" "}
                    {this.state.campaign["user"]["firstName"]}{" "}
                    {this.state.campaign["user"]["lastName"]}{" "}
                  </h5>
                  <span>
                    <CiLocationOn /> Nairobi Kenya
                  </span>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="text-end">
                  <a
                    href={"mailto:" + this.state.campaign["user"]["email"]}
                    className="btn tufund_button donate_btn pt-2 pb-2"
                  >
                    {" "}
                    Contact
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  }
}
