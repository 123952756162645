import React from "react";
import {Badge, Col, Container, Row} from "react-bootstrap";
import logo from "../../assets/images/logo.svg";
import {SlSocialTwitter, SlSocialFacebook, SlSocialInstagram, SlSocialYoutube}  from "react-icons/sl";
export default class Footer extends React.Component<any, any>{
    public render() {
        return (
            <>
            <Container className="Footer pt-5 pb-5" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-sm-center text-center mb-3 pb-3" >
                                    <img
                                        className="d-inline-block align-top mb-4"
                                        src={logo}
                                        width={150}
                                        height={30}
                                        alt="logo" />
                                    <br/>

                                    <Badge bg="dark"> <SlSocialYoutube/>  </Badge> &nbsp;
                                    <Badge bg="dark"> <SlSocialFacebook/>  </Badge>&nbsp;
                                    <Badge bg="dark"> <SlSocialInstagram/>  </Badge>&nbsp;
                                    <Badge bg="dark"> <SlSocialTwitter/>  </Badge>


                                </Col>
                                <Col xs={4} sm={4} md={3} lg={3} xl={3} className="text-sm-center text-center">

                                <ul>
                                    <li><b>About</b></li>
                                    <li>About Us</li>
                                    <li>Service</li>
                                    <li>Rules &amp; Terms</li>
                                </ul>
                                </Col>
                                <Col xs={4} sm={4} md={3} lg={3} xl={3} className="text-sm-center text-center">

                                    <ul>
                                        <li><b>Services</b></li>
                                        <li>Help Center</li>
                                        <li>Money Refund</li>
                                        <li>Terms &amp; Policy</li>
                                        <li>Open dispute</li>
                                    </ul>
                                </Col>

                                <Col xs={4} sm={4} md={3} lg={3} xl={3} className="text-sm-center text-center">

                                    <ul>
                                        <li><b>Company</b></li>
                                        <li>Login</li>
                                        <li>Register</li>
                                        <li>Help</li>
                                        <li>Lost Account</li>
                                    </ul>
                                </Col>

                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}