import React from "react";
import Navigation from "../../LandingPage/Subcomponents/Navigation";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//@ts-ignore;
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import * as filestack from "filestack-js";
import toast, { Toaster } from "react-hot-toast";
import Swal from 'sweetalert2'

import "./subcomponents.scss";
import {
  createCampaign,
  getAllCampaignTypes,
  getUserWithToken,
} from "../../Api/ApiRequester";
import { BsCheckCircleFill } from "react-icons/bs";
import ConfettiExplosion from "react-confetti-explosion";

export default class CreateCampaign extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDark: false,
      fileTypes: ["JPG", "PNG", "JPEG"],
      validated: false,
      campaign_name: "",
      campaign_description: "",
      campaign_type_id: null,
      campaign_target: null,
      account_number: "",
      start_date: new Date(),
      end_date: new Date(),
      campaignTypes: [],
      campaign_images: [],
      modalShow: false,
      hasGifts: false,
      belongsTo: 0,
    };
    this.validateForm = this.validateForm.bind(this);
  }

  handleFileUpload = (file: any) => {
    for (var i = 0; i < file.length; i++) {
      console.log(file[i]);
      this.uploadImage(file[i]);
    }
  };
  onDateChange = (startDate: any, endDate: any) => {
    var formattedstartDate = this.formatDate(startDate);
    var formatedEndDate = this.formatDate(endDate);

    this.setState({
      start_date: formattedstartDate,
      end_date: formatedEndDate,
    });
  };

  validateForm = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ validated: true });
    this.handleFormSubmit(event);
  };

  handleFormSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var data = {
      campaign_name: this.state.campaign_name,
      campaign_description: this.state.campaign_description,
      campaign_target: this.state.campaign_target,
      campaign_type_id: this.state.campaign_type_id,
      has_gifts: true,
      belongs_to: this.state.belongsTo,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      account_number: this.state.account_number,
      image_list: this.state.campaign_images,
    };

    const response = this.handleApiCall(data);
    toast.promise(response, {
      loading: "Creating Campaign",
      success: "Your campaign is ready!",
      error: "Error when creating account",
    });
    response.then((res: any) => {
      var response = JSON.parse(JSON.stringify(res));
      if (response["status"] === 200) {
        this.setState({ modalShow: true });
        console.log(response);
        if(this.state.hasGifts){
          var campaignId = response["data"]["campaign"]["campaignId"];
          console.log(campaignId);
          if(campaignId !== undefined){
            window.location.href="/dashboard/create/gift/"+campaignId;
          }
          // 404";
        }
      } else {
        toast.error("Unable to create campaign");
        this.setState({ isLoading: false });
      }
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unable to create campaign",
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    })
  };
  formatDate = (date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  handleFiles = (res: any) => {
    var response = JSON.parse(JSON.stringify(res));
    var fileList = response["filesUploaded"];
    var campaign_images = this.state.campaign_images;

    for (var i = 0; i < fileList.length; i++) {
      var url = fileList[i]["url"];
      console.log(url);
      campaign_images.push(url);
    }
    this.setState({ campaign_images: campaign_images });
    toast.success("Images Uploaded!");
  };

  uploadImage = async (image: any) => {
    const apikey = "AwxAZSN3QAeJRMSgX7ViNz";
    const client = filestack.init(apikey);

    var options = {
      onUploadDone: (res: any) => this.handleFiles(res),

      accept: ["image/jpeg", "image/*"],
      fromSources: ["local_file_system", "url"],
      maxFiles: 5,
      minFiles: 1,
    };

    const picker = client.picker(options);
    picker.open();
  };
  handleApiCall = async (data: any) => {
    return createCampaign(data);
  };

  componentDidMount() {
    var uuid = this.props.match.params.uuid;
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });

    this.getUser(uuid);
    this.getCampaignTypes();
  }
  getUser = (token: any) => {
    getUserWithToken(token).then((res: any) => {
      var response = JSON.parse(JSON.stringify(res));
      var userId = response["user"]["userId"];
      this.setState({ belongsTo: userId });
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Accessing invalid url',
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    });
  };
  getCampaignTypes = () => {
    getAllCampaignTypes().then((res: any) => {
      var response = JSON.parse(JSON.stringify(res));
      var campaign_types = response["campaignTypes"];
      console.log(campaign_types);
      this.setState({ campaignTypes: campaign_types });
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to fetch campaign types',
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    });
  };
  public render() {
    return (
      <>
        <Navigation />
        <Toaster position="top-center" />
        <Container className="CreateCampaign mt-5">
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="mt-5">
              <Card data-bs-theme={this.state.isDark ? "dark" : "light"}>
                <Card.Body>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5>Create A Fundraiser</h5>
                  </Col>
                  <Form
                    className="mt-4 mb-2"
                    noValidate
                    validated={this.state.validated}
                    onSubmit={this.validateForm}
                  >
                    <Form.Group className="mb-3">
                      <FloatingLabel label="Campaign Name" className="mb-3">
                        <Form.Control
                          required
                          value={this.state.campaign_name}
                          onChange={(e: any) =>
                            this.setState({ campaign_name: e.target.value })
                          }
                          type="text"
                          placeholder="campaign name"
                        />
                      </FloatingLabel>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid campaign name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 border pt-2 pb-2">

                      <Form.Label>Campaign Description</Form.Label>
                      <ReactQuill theme="snow" value={this.state.campaign_description} onChange={(e:any)=>this.setState({ campaign_description: e})} />

                    </Form.Group>

                    <Form.Group className="mb-3">
                      <FileUploader
                        required
                        multiple={true}
                        handleChange={this.handleFileUpload}
                        name="file"
                        types={["JPG", "PNG", "JPEG"]}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <FloatingLabel label="What type of fundraiser">
                        <Form.Select
                          required
                          value={this.state.campaign_type_id}
                          onChange={(e: any) =>
                            this.setState({ campaign_type_id: e.target.value })
                          }
                          aria-label="Fundraiser types"
                        >
                          <option>Please Select ...</option>
                          {this.state.campaignTypes &&
                            this.state.campaignTypes.map(
                              (value: any, index: any) => {
                                return (
                                  <option
                                    key={index}
                                    value={value.campaignTypeId}
                                  >
                                    {value.campaignType}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label htmlFor="target">
                          Target To Raise
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-target">
                            KES.
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="You want to raise KES?"
                            aria-label="target"
                            aria-describedby="basic-target"
                            pattern="\d*"
                            id="target"
                            value={this.state.campaign_target}
                            onChange={(e: any) =>
                              this.setState({ campaign_target: e.target.value })
                            }
                            required
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label htmlFor="accountNumber">
                          Account Number
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-target">
                            e.g knh
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Account Name (MPESA)"
                            aria-label="target"
                            aria-describedby="basic-target"
                            pattern="\d*"
                            id="accountNumber"
                            required
                            value={this.state.account_number}
                            onChange={(e: any) =>
                              this.setState({ account_number: e.target.value })
                            }
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="accountNumber">
                          Campaign Start &amp; End Date
                        </Form.Label>

                        <RangeDatePicker
                          data-bs-theme={this.state.isDark ? "dark" : "light"}
                          startDate={new Date(this.state.start_date)}
                          endDate={new Date(this.state.end_date)}
                          onChange={(startDate: any, endDate: any) =>
                            this.onDateChange(startDate, endDate)
                          }
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date(2100, 0, 1)}
                          dateFormat="D"
                          monthFormat="MMM YYYY"
                          startDatePlaceholder="Start Date"
                          endDatePlaceholder="End Date"
                          disabled={false}
                          className="my-own-class-name"
                          startWeekDay="monday"
                        />
                      </Form.Group>
                      <Row>
                        <Form.Group as={Col} className="">
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="gifts"
                            label="My campaign has gifts"
                            onClick={() =>
                              this.setState({ hasGifts: !this.state.hasGifts })
                            }
                          />
                        </Form.Group>
                      </Row>

                      <Form.Group as={Col} className="text-center">
                        <Button
                          disabled={this.state.isLoading ? true : false}
                          type="submit"
                          className="tufund_button w-75"
                        >
                          Create Campaign
                        </Button>
                      </Form.Group>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          animation={true}
          centered
          size="sm"
          data-bs-theme={this.state.isDark ? "dark" : "light"}
          className="notificationModal"
        >
          <Modal.Body
            className="text-center"
            data-bs-theme={this.state.isDark ? "dark" : "light"}
          >
            <h6>
              {" "}
              <BsCheckCircleFill /> Campaign is ready for approval.
            </h6>
            <ConfettiExplosion
              particleCount={200}
              particleSize={6}
              duration={5000}
              onComplete={() => this.setState({ modalShow: false })}
              zIndex={10}
              force={0.8}
              height="30vh"
              width={600}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
