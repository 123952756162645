import React from "react";
import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
//@ts-ignore;
import { RangeDatePicker } from "react-google-flight-datepicker";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./subcomponents.scss"
import toast, { Toaster } from "react-hot-toast";
import { createGift} from "../../Api/ApiRequester";
import Swal from 'sweetalert2'

export default class CreateGift extends React.Component<any, any>{
    constructor(props:any) {
        super(props);
        this.state={
            gift_name:"",
            isDark:false,
            gift_price:null,
            gift_description:"",
            quantity:null,
            is_deliverable:false,
            delivery_instructions:"",
            is_collectable:false,
            collection_instructions:"",
            isFreeDelivery:false,
            deliveryFee:0,
            start_date: new Date(),
            end_date: new Date(),
            validated:false,
            campaign_id:null
        }
    }
    componentDidMount() {
        var campaignId = this.props.match.params.campaignId;
        this.setState({campaign_id:campaignId})
        console.log(campaignId);

        const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
        if (isDarkSystem.matches) {
            this.setState({ isDark: true });
        }
        isDarkSystem.addListener((ev) => {
            if (ev.matches) {
                this.setState({ isDark: true });
            }
        });
    }

    handleDeliverableSwitch =(is_deliverable:boolean) =>{
        this.setState({is_deliverable: !is_deliverable, is_collectable: !this.state.is_collectable})
        if(!is_deliverable){
            this.setState({
                isFreeDelivery:false,
            })
        }


    }

    handleCollectionSwitch =() =>{
        this.setState({
            isFreeDelivery: !this.state.isFreeDelivery,
            is_collectable: !this.state.is_collectable

        })
    }

    onDateChange = (startDate: any, endDate: any) => {
        var formattedstartDate = this.formatDate(startDate);
        var formatedEndDate = this.formatDate(endDate);

        this.setState({
            start_date: formattedstartDate,
            end_date: formatedEndDate,
        });
    };
    formatDate = (date: any) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    handleSubmit = (event:any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

       this.setState({validated:true})
        this.handleGiftCreation(event);
    };

    handleGiftCreation = (e:any) =>{
        e.preventDefault();
        var gift_name = this.state.gift_name
        var gift_description = this.state.gift_description;
        var price = this.state.gift_price;
        var quantity=this.state.quantity;
        var is_deliverable = this.state.is_deliverable;
        var  is_free_delivery= this.state.isFreeDelivery;
        var delivery_fee = this.state.deliveryFee;
        if(gift_name === ""){
            toast.error("Gift name is blank");
        }else{
            if(gift_description === ""){
                toast.error("Gift description is blank");
            }else{
                if (price ===""){
                    toast.error("Gift price is blank");
                }else{
                    if (quantity ===""){
                        toast.error("Gift quantity is blank");
                    }else{
                        if(!is_free_delivery){
                            if(delivery_fee === ""){
                                toast.error("You must set a delivery fee");

                            }
                        }else{
                            var data={
                                "gift_name": gift_name,
                                "gift_description":gift_description,
                                "price": parseInt(price),
                                "campaign_id": parseInt(this.state.campaign_id),
                                "gift_quantity": parseInt(quantity),
                                "start_date": this.state.start_date,
                                "end_date": this.state.end_date,
                                "collection_instructions": this.state.collection_instructions,
                                "delivery_fee": parseInt(delivery_fee),
                                "delivery_instructions": this.state.delivery_instructions,
                                "is_free_delivery": is_free_delivery,
                                "is_collectable": this.state.is_collectable,
                                "is_deliverable": is_deliverable
                            }
                            const response = this.handleApiCall(data);
                            toast.promise(response, {
                                loading: "Creating Gift",
                                success: "Your Gift Was Created",
                                error: "Error when creating Gift",
                            });
                            response.then((res:any)=>{
                                if(res.status === 200){
                                    Swal.fire({
                                        title: 'Gift Was Created',
                                        showCancelButton: true,
                                        confirmButtonText: 'Done',
                                        denyButtonText: `Create Another`,
                                    }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                            Swal.fire('All Gifts Created!', '', 'success')
                                        } else if (result.isDismissed) {
                                        window.location.href="/"
                                        }
                                    })
                                }
                            }).catch((_error:any)=>{
                               // var errors = error["response"]["data"]["error"];
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: "Unable to create gift",
                                    footer: '<a href="">Report this issue</a>',
                                    showClass: {
                                        popup: 'animate__animated animate__fadeInDown'
                                    },
                                    hideClass: {
                                        popup: 'animate__animated animate__fadeOutUp'
                                    }

                                })
                            })

                        }
                    }
                }
            }
        }




    }
    handleApiCall = async (data: any) => {
        return createGift(data);
    };


    public render() {
        return (
            <>
                <Toaster position="top-center" />
                <Container  className="CreateGift " data-bs-theme={this.state.isDark ? "dark" : "light"}>
                    <Row className="mt-5">
                        <Col md={{ span: 6, offset: 3 }}>
                            <h6>Create Gifts for your campaign</h6>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Gift Name"
                                        className="mb-3"

                                    >
                                        <Form.Control required type="text" placeholder="" value={this.state.gift_name} onChange={(e:any)=>this.setState({gift_name:e.target.value})} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Gift Description</Form.Label>
                                    <ReactQuill  theme="snow" value={this.state.gift_description} onChange={(e:any)=>this.setState({ gift_description: e})} />

                                </Form.Group>
                               <Row>
                                   <Form.Group className="mb-3" as={Col}>
                                       <FloatingLabel
                                           controlId="floatingInput"
                                           label="Gift Price (Per Unit)"
                                           className="mb-3"

                                       >
                                           <Form.Control required type="text" placeholder="" value={this.state.gift_price} onChange={(e:any)=>this.setState({gift_price:e.target.value})} />
                                       </FloatingLabel>
                                   </Form.Group>

                                   <Form.Group className="mb-3" as={Col}>
                                       <FloatingLabel
                                           controlId="floatingInput"
                                           label="How Many Gifts Are There?"
                                           className="mb-3"
                                       >
                                           <Form.Control  required type="text" placeholder="" value={this.state.quantity} onChange={(e:any)=>this.setState({quantity:e.target.value})} />
                                       </FloatingLabel>
                                   </Form.Group>


                               </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="accountNumber">
                                        Gift Sale Start &amp; End Date
                                    </Form.Label>

                                    <RangeDatePicker
                                        data-bs-theme={this.state.isDark ? "dark" : "light"}
                                        startDate={new Date(this.state.start_date)}
                                        endDate={new Date(this.state.end_date)}
                                        onChange={(startDate: any, endDate: any) =>
                                            this.onDateChange(startDate, endDate)
                                        }
                                        minDate={new Date(1900, 0, 1)}
                                        maxDate={new Date(2100, 0, 1)}
                                        dateFormat="D"
                                        monthFormat="MMM YYYY"
                                        startDatePlaceholder="Start Date"
                                        endDatePlaceholder="End Date"
                                        disabled={false}
                                        className="my-own-class-name"
                                        startWeekDay="monday"
                                    />
                                </Form.Group>

                                <Row  className={this.state.is_deliverable ? "mb-3 animate__animated  animate__fadeIn " : " animate__fadeOut d-none"}>


                                <Form.Group className="mb-3 ">
                                    <FloatingLabel label="Enter Delivery Instructions. Tell customers the date,mode ...">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter Delivery Instructions. Tell customers the date,mode ...."
                                            style={{ height: "100px" }}
                                            value={this.state.delivery_instructions}
                                            required
                                            onChange={(e: any) =>
                                                this.setState({
                                                    delivery_instructions: e.target.value,
                                                })
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>


                                </Row>
                                <Row  className={this.state.isFreeDelivery && this.state.is_deliverable ? "mb-3 animate__animated  animate__fadeIn " : " animate__fadeOut d-none"}>
                                    <Form.Group className="mb-3 ">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Delivery Fee"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="" value={this.state.deliveryFee} onChange={(e:any)=>this.setState({deliveryFee:e.target.value})} />
                                        </FloatingLabel>
                                    </Form.Group>

                                </Row>

                                <Row  className={this.state.is_collectable && this.state.is_deliverable === false ? "mb-3 animate__animated  animate__fadeIn " : " animate__fadeOut d-none"}>


                                    <Form.Group className="mb-3 ">
                                        <FloatingLabel label="Gift Collection Details. e.g collect at gate">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Gift Collection Details"
                                                style={{ height: "100px" }}
                                                value={this.state.collection_instructions}

                                                onChange={(e: any) =>
                                                    this.setState({
                                                        collection_instructions: e.target.value,
                                                    })
                                                }
                                            />
                                        </FloatingLabel>
                                    </Form.Group>


                                </Row>

                                <Form.Group className="mb-1 ">
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="isDeliver"
                                        label="This gift is deliverable"
                                        onChange={()=>this.handleDeliverableSwitch(this.state.is_deliverable)}
                                        defaultChecked={this.state.is_deliverable}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="hasDeliveryFee"
                                        label="Has Delivery Fee"
                                        disabled={!this.state.is_deliverable}
                                        defaultChecked={this.state.isFreeDelivery}
                                        onChange={()=>this.setState({isFreeDelivery: !this.state.isFreeDelivery})}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="isCollection"
                                        label="Customer to collect gift"
                                        disabled={this.state.is_deliverable}
                                        defaultChecked={this.state.is_collectable}
                                        onChange={()=>this.handleCollectionSwitch()}
                                    />
                                </Form.Group>
                              <Row>
                                  <Form.Group as={Col}>
                                      <Button type="submit" className="tufund_button w-75"> Create Gift </Button>
                                  </Form.Group>

                              </Row>
                            </Form>

                        </Col>
                        <Col md={{ span: 3, offset: 0 }}>
                            <h6>Your gifts</h6>
                        </Col>

                    </Row>
                </Container>
            </>
        );
    }
}