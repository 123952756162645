import React, { Component } from "react";
import "./DashBoard.scss";
import DashboardMenu from "./SubComponents/DashboardMenu";
export default class DashBoard extends Component<any, any> {
  public render() {
    return <>
    <div className="Dashboard">
      <DashboardMenu/>
    </div>
    </>;
  }
}


