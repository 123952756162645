import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { validateEmail } from "../../Api/ApiRequester";
//@ts-ignore;
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import "./subcomponents.scss";
import Swal from "sweetalert2";

export default class VerifyMail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      varified: null,
      verificationFailed: false,
      isLoading: true,
      verified: false,
      uuid: "",
    };
  }
  componentDidMount() {
    var uuid = this.props.match.params.uuid;
    this.handleVerifyUser(uuid);
    this.setState({ uuid: uuid });
    //verify user
  }

  handleVerifyUser = (uuid: any) => {
    validateEmail(uuid).then((res: any) => {
      var response = JSON.parse(JSON.stringify(res));
      if (response["status"]) {
        this.setState({ verified: true, isLoading: false });
      } else {
        this.setState({
          verified: false,
          isLoading: false,
          verificationFailed: true,
        });
      }
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unable to validate email",
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    });
  };
  public render() {
    return (
      <>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner={<HashLoader color="#fff" />}
        >
          <Container className="varify">
            <Row>
              <Col sm={12} xs={12} md={{ span: 6, offset: 3 }} className="mt-5">
                <Card className="text-center">
                  <Card.Body>
                    <h6 className={this.state.verified ? "d-none" : ""}>
                      {this.state.verificationFailed ? (
                        <> Verification Failed</>
                      ) : (
                        <> Awaiting Verification </>
                      )}
                    </h6>
                    <div className={this.state.verified ? "mb-2" : "d-none"}>
                      <p>Congratulations!</p>
                      <p>
                        Your account has been successfully verified. You are now
                        a trusted member of our community, and your access to
                        all of our platform's features has been fully unlocked.
                      </p>
                      <p>With your verified account, you can:</p>
                      <ul>
                        <li>Explore and engage with our platform's content.</li>
                        <li>Participate in campaigns and events.</li>
                        <li>
                          Connect with other members who share your interests.
                        </li>
                        <li>Enjoy a seamless and enhanced user experience.</li>
                      </ul>
                      <p>
                        We are excited to have you on board and look forward to
                        the positive contributions you'll make within our
                        community.
                      </p>
                      <div className="w-100 text-center">
                        <Button
                          onClick={() =>
                            (window.location.href =
                              "/dashboard/create/campaign/" + this.state.uuid)
                          }
                          className="tufund_button w-100 mt-3"
                        >
                          {" "}
                          Create Your First Campaign{" "}
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </>
    );
  }
}
