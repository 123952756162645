import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { parsePhoneNumber } from "libphonenumber-js";
//@ts-ignore;
import { Helmet } from "react-helmet";
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from "react-icons/md";
import "./Authentication.scss";
import { BsCheckCircleFill, BsFillLockFill } from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import toast, { Toaster } from "react-hot-toast";
import { create_user } from "../Api/ApiRequester";
import ConfettiExplosion from "react-confetti-explosion";
import Swal from "sweetalert2";

export default class Authentication extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDark: false,
      isPersonal: true,
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      hasAgreed: false,
      formValid: false,
      password: "",
      modalShow: false,
    };
  }
  componentDidMount() {
    const isDarkSystem = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkSystem.matches) {
      this.setState({ isDark: true });
    }
    isDarkSystem.addListener((ev) => {
      if (ev.matches) {
        this.setState({ isDark: true });
      }
    });
  }

  handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ formValid: false });
    }

    this.setState({ formValid: true });
    await this.handleUserCreation(event);
  };

  handleUserCreation = async (e: any) => {
    e.preventDefault();
    console.log("---");
    const mobile_number = parsePhoneNumber(this.state.mobileNumber, "KE");
    var phoneNumber =
      mobile_number["countryCallingCode"] + mobile_number["nationalNumber"];
    this.setState({ mobile_number: phoneNumber });

    var data = {
      is_personal_account: this.state.isPersonal,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      mobile_number: phoneNumber,
      agreed_to_terms: this.state.hasAgreed,
      password: this.state.password,
    };

    const response = this.handleApiCall(data);
    toast.promise(response, {
      loading: "Creating Account",
      success: "Your account is ready!",
      error: "Error when creating account",
    });
    response.then((res: any) => {
      console.log(res);
      this.setState({ modalShow: true });
    }).catch((error:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unable to create user",
        footer: '<a href="">Report this issue</a>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      })
    });
  };

  handleApiCall = async (data: any) => {
    console.log("here");
    return create_user(data);
  };

  public render() {
    return (
      <>
        <Helmet>
          <title> Tufund - Sign Up </title>
          <meta
            name="description"
            content="Ready to make a difference? Sign up for Tufund's fundraising community today and start your journey towards creating positive change. Join passionate individuals and organizations in supporting causes that matter. Together, we can turn dreams into reality. Sign up now"
          />
          <link rel="canonical" href="http://tufund.org/signup" />
        </Helmet>
        <Toaster position="top-center" />
        <div  className="Authentication"> 
        <Container>
          <Row className="mt-sm-3 mt-md-5 mt-3">
            <Col md={{ span: 6, offset: 3 }} className="mt-sm-0 mt-md-3 ">
              <div
                onClick={() => (window.location.href = "/")}
                className="text-end pb-3"
              >
                {" "}
                <TfiClose />
              </div>

              <Card
                className="shadow"
                data-bs-theme={this.state.isDark ? "dark" : "light"}
              >
                <Card.Header className="text-center">
                  Create Your Account
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="text-start"
                    >
                      <h5>Welcome to Tufund</h5>
                    </Col>
                    <Form
                      noValidate
                      validated={this.state.formValid}
                      onSubmit={this.handleSubmit}
                    >
                      <Row className="mb-3">
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="mb-2"
                          onClick={() => this.setState({ isPersonal: true })}
                        >
                          <Card
                            body
                            className={
                              this.state.isPersonal
                                ? "selected-item type_selector"
                                : "type_selector"
                            }
                          >
                            {this.state.isPersonal ? (
                              <>
                                {" "}
                                <MdRadioButtonChecked />{" "}
                              </>
                            ) : (
                              <>
                                {" "}
                                <MdRadioButtonUnchecked />{" "}
                              </>
                            )}{" "}
                            Personal
                          </Card>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="mb-2"
                          onClick={() => this.setState({ isPersonal: false })}
                        >
                          <Card
                            body
                            className={
                              this.state.isPersonal
                                ? "type_selector"
                                : "selected-item type_selector"
                            }
                          >
                            {!this.state.isPersonal ? (
                              <>
                                {" "}
                                <MdRadioButtonChecked />{" "}
                              </>
                            ) : (
                              <>
                                {" "}
                                <MdRadioButtonUnchecked />{" "}
                              </>
                            )}
                            Organization
                          </Card>
                        </Col>
                      </Row>

                      <Row className="">
                        <Form.Group as={Col} controlId="formGridFirstName">
                          <FloatingLabel
                            controlId="formFirstName"
                            label="First Name"
                            className="mb-3"
                          >
                            <Form.Control
                              minLength={1}
                              required
                              value={this.state.firstName}
                              onChange={(e: any) =>
                                this.setState({ firstName: e.target.value })
                              }
                              type="text"
                              placeholder="First Name"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Your First Name
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastName">
                          <FloatingLabel
                            controlId="formGridLastName"
                            label="Last Name"
                            className="mb-3"
                          >
                            <Form.Control
                              minLength={1}
                              required
                              type="text"
                              value={this.state.lastName}
                              onChange={(e: any) =>
                                this.setState({ lastName: e.target.value })
                              }
                              placeholder="Last Name"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Your Last Name
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                          <FloatingLabel
                            controlId="formGridEmail"
                            label="Email"
                            className="mb-3"
                          >
                            <Form.Control
                              required
                              value={this.state.email}
                              onChange={(e: any) =>
                                this.setState({ email: e.target.value })
                              }
                              type="email"
                              placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Your Email
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} controlId="formGridNumber">
                          <FloatingLabel
                            controlId="formGridNumber"
                            label="Phone Number"
                            className="mb-3"
                          >
                            <Form.Control
                              minLength={10}
                              required
                              value={this.state.mobileNumber}
                              onChange={(e: any) =>
                                this.setState({ mobileNumber: e.target.value })
                              }
                              type="text"
                              pattern="\d*"
                              placeholder="Phone Number"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Your Mobile Number as 07xxx
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} controlId="formPasswoed">
                          <FloatingLabel
                            controlId="formPasswoed"
                            label="Password"
                            className="mb-3"
                          >
                            <Form.Control
                              minLength={6}
                              required
                              value={this.state.password}
                              onChange={(e: any) =>
                                this.setState({ password: e.target.value })
                              }
                              type="password"
                              placeholder="Password"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter a strong password
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formAgree">
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="agreeToTerms"
                            required
                            defaultChecked={this.state.hasAgreed}
                            onChange={() =>
                              this.setState({
                                hasAgreed: !this.state.hasAgreed,
                              })
                            }
                            label={
                              <>
                                By proceeding, i agree to the{" "}
                                <a href="/terms"> Terms &amp; Conditions </a>{" "}
                              </>
                            }
                            feedback="You must agree before submitting."
                            feedbackType="invalid"
                          />
                        </Form.Group>
                      </Row>
                      <Form.Group
                        as={Col}
                        controlId="formAgree"
                        className="text-center"
                      >
                        <Button
                          type="submit"
                          className="tufund_button w-75 p-2"
                        >
                          <span className="float-start">
                            {" "}
                            <BsFillLockFill />
                          </span>
                          <span>Sign Up</span>
                        </Button>
                        <p>
                          Already have an account? <a href="/">Sign In</a>{" "}
                        </p>
                      </Form.Group>
                      <Row></Row>
                    </Form>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        </div>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          animation={true}
          centered
          size="sm"
          data-bs-theme={this.state.isDark ? "dark" : "light"}
          className="notificationModal"
        >
          <Modal.Body
            className="text-center"
            data-bs-theme={this.state.isDark ? "dark" : "light"}
          >
            <h6>
              {" "}
              <BsCheckCircleFill /> Please check your email.
            </h6>
            <ConfettiExplosion
              particleCount={200}
              particleSize={6}
              duration={5000}
              onComplete={() => this.setState({ modalShow: false })}
              zIndex={10}
              force={0.8}
              height="30vh"
              width={600}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
